import Swiper from "swiper";
const navigation = document.querySelector(".navigation");
const tabs = document.querySelectorAll(".tabs");
const productImageGallery = document.querySelectorAll(".product__images--gallery");
const navTrigger = document.querySelector(".navigation__mobile--trigger");
const slider = document.querySelectorAll(".slider");

const session = window.sessionStorage.getItem("session");

if (false) {
	const template = document.createElement("div");
	template.classList.add("notice");
	const innerHTML = `
        <div class="notice__container">
                <div class="notice__header">
                    <h2>Wichtiger Hinweis</h2>
                    <span class="notice__close">x</span>
                </div>
                <div class="notice__body">
                    <p>
                        Wir befinden uns im Betriebsurlaub. Ab dem 26.10.2020 werden wir deine Bestellungen wieder wie gewohnt aufnehmen und verschicken. Vielen Dank für dein Verständnis.
                        </br>
                        </br>
                        Mit freundlichen Grüßen
                        </br>
                        Dein Insel-Salz Team
                    </p>
                </div>
            </div>
    `;
	template.innerHTML = innerHTML;

	template.querySelector(".notice__close").addEventListener("click", () => {
		window.sessionStorage.setItem("session", `${new Date()}`);
		template.remove();
	});

	document.querySelector("body").append(template);
}

const mapContainer = document.querySelector(".standorte");
const initLocationMap = () => {
	const zoom = 12;
	const initialCoords = [54.3766547, 13.6971513];
	const locations = mapContainer.querySelectorAll(".meta");
	const mapWrapper = mapContainer.querySelector(".standorte__map");

	const map = L.map(mapWrapper, {
		center: initialCoords,
		zoom: zoom,
		scrollWheelZoom: false,
	});
	L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(map);

	locations.forEach((location) => {
		let coords = location.dataset.coords;
		coords = coords ? coords.split(",") : [];

		if (coords.length) {
			if (location.dataset.store == true) {
				const icon = L.icon({
					iconUrl: `${window.location.origin}/wp-content/themes/inselsalz/assets/img/marker-dark.png`,
					iconiconSize: [25, 41],
					iconAnchor: [22.5, 41],
				});

				L.marker(coords, { icon: icon }).addTo(map);
			} else {
				L.marker(coords).addTo(map);
			}
		}
	});
};
if (mapContainer) {
	initLocationMap();
}
const mobileNav = document.querySelector(".navigation__mobile");
if (mobileNav) {
	mobileNav.querySelectorAll("a").forEach((link) => {
		link.addEventListener("click", (event) => {
			mobileNav.classList.remove("open");
		});
	});
}

const initProductSlider = (elem) => {
	new Swiper(elem.querySelector(".swiper-container"), {
		autoHeight: elem.classList.contains("autoheight"),
		loop: true,
		autoplay: { delay: 4000 },
		pagination: {
			el: elem.querySelector(".swiper-pagination"),
		},
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl: elem.parentElement.querySelector(".swiper-controls-next"),
			prevEl: elem.parentElement.querySelector(".swiper-controls-prev"),
		},
		disableOnInteraction: true,
		breakpoints: {
			768: {
				slidesPerView: elem.classList.contains("single") ? 1 : 3,
				spaceBetween: 0,
			},
		},
		preventClicks: false,
		preventClicksPropagation: false,
	});
};

if (slider.length) {
	slider.forEach((s) => {
		initProductSlider(s);
	});
}
if (navTrigger) {
	navTrigger.addEventListener("click", (e) => {
		const body = document.querySelector("body");
		const mobileNav = document.querySelector(".navigation__mobile");
		if (mobileNav.classList.contains("open")) {
			navTrigger.classList.remove("open");
			mobileNav.classList.remove("open");
			body.style.overflow = "auto";
		} else {
			navTrigger.classList.add("open");
			mobileNav.classList.add("open");
			body.style.overflow = "hidden";
		}
	});
}

const initTabs = (element) => {
	const contentContainer = element.querySelector(".tabs__body");
	contentContainer.style.height = contentContainer.querySelector(".active").scrollHeight + "px";
	const triggers = element.querySelectorAll(".tabs__header--trigger");
	triggers.forEach((trigger) =>
		trigger.addEventListener("click", (e) => {
			const target = e.target;
			triggers.forEach((t) => t.classList.remove("active"));
			target.classList.add("active");
			const containers = element.querySelectorAll(".tabs__body--content");
			containers.forEach((container) => container.classList.remove("active"));
			element.querySelector(`#${target.dataset.target}`).classList.add("active");
			contentContainer.style.height = contentContainer.querySelector(".active").scrollHeight + "px";
		})
	);
};

if (tabs && tabs.length) {
	tabs.forEach((tab) => initTabs(tab));
}

const initProductGallery = (container) => {
	const targetImage = document.querySelector(".product__images--thumbnail img");
	const triggers = container.querySelectorAll("img");

	triggers.forEach((trigger) =>
		trigger.addEventListener("click", (e) => {
			targetImage.src = e.target.src;
			targetImage.srcset = e.target.srcset;
		})
	);
};

if (productImageGallery && productImageGallery.length) {
	productImageGallery.forEach((gallery) => initProductGallery(gallery));
}

if (window.innerWidth < 768) {
	document.addEventListener("scroll", (e) => {
		if (window.pageYOffset > 80) {
			navigation.classList.add("scrolled");
		} else {
			navigation.classList.remove("scrolled");
		}
	});
}
